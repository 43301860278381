<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.9997 0.32373C10.8967 0.32373 10.1389 1.03697 9.54573 1.85512C8.96181 2.66053 8.35061 3.83207 7.60633 5.2587L2.12712 15.7605C1.47729 17.0059 0.937229 18.041
0.644639 18.8857C0.341986 19.7595 0.209609 20.6909 0.733531 21.5552C1.25745 22.4195 2.1444 22.7329 3.0591 22.8688C3.9434 23.0001 5.11084  23.0001 6.51558 23H17.4837C18.8885
23.0001 20.0559 23.0001 20.9402 22.8688C21.8549 22.7329 22.7419 22.4195 23.2658 21.5552C23.7897 20.6909 23.6573 19.7595 23.3547 18.8857C23.0621 18.041 22.522 17.006
21.8722 15.7606L16.393 5.25874C15.6487 3.8321 15.0375 2.66054 14.4536 1.85512C13.8604 1.03697 13.1026 0.32373 11.9997 0.32373Z"
      fill="#FCC693"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13 17C13 16.4477 12.5523 16 12 16C11.4477 16 11 16.4477 11 17L11 18C11 18.5523 11.4477 19 12 19C12.5523 19 13 18.5523
13 18L13 17ZM13 8C13 7.44772 12.5523 7 12 7C11.4477 7 11 7.44772 11 8L11 14C11 14.5523 11.4477 15 12 15C12.5523 15 13 14.5523 13 14L13 8Z"
      fill="#C14600"
    />
  </svg>
</template>
